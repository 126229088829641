<template>
  <div class="login-main">
    <div class="login_tab">
      <div
        v-for="(item, index) in tabList"
        v-show="item.show"
        :key="index"
        :class="['login_tab_list', item.fal ? 'login_tab_text' : '']"
        @click="onTab(item, index)"
      >
        {{ item.name }}
        <div v-if="item.fal" class="login_tab_active" />
      </div>
    </div>
    <el-form ref="form" :model="form" :rules="rules" style="padding:0">
      <template v-if="type === 'email'">
        <div class="phone_code">
          <el-form-item prop="email">
            <el-input
              v-model="form.email"
              clearable
              style="margin-top: 20px"
              placeholder="请输入邮箱"
            />
          </el-form-item>
          <el-form-item prop="email_code">
            <el-input
              v-model="form.email_code"
              placeholder="请输入邮箱验证码"
            >
              <template slot="suffix">
                <span class="span1">|</span>
                <el-button :disabled="emailCodeDisabled" type="text" @click="get_email_code">{{ emailCodeText }}</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="form.password"
              clearable
              show-password
              placeholder="请输入登录密码"
            />
          </el-form-item>
          <el-form-item prop="re_password">
            <el-input
              v-model="form.re_password"
              clearable
              show-password
              placeholder="请再次输入登录密码"
            />
          </el-form-item>
        </div>
      </template>

      <template v-if="type === 'pwd'">
        <div class="phone_code">
          <el-form-item prop="username">
            <el-input
              v-model="form.username"
              style="margin-top: 20px"
              clearable
              placeholder="请输入登录名"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="form.password"
              clearable
              show-password
              placeholder="请输入登录密码"
            />
          </el-form-item>
          <el-form-item prop="re_password">
            <el-input
              v-model="form.re_password"
              clearable
              show-password
              placeholder="请再次输入登录密码"
            />
          </el-form-item>
        </div>
      </template>

      <template v-if="type === 'phone'">
        <div class="phone_code">
          <el-form-item prop="phone">
            <el-input
              v-model="form.phone"
              style="margin-top: 20px"
              clearable
              placeholder="请输入手机号"
            />
          </el-form-item>
          <el-form-item prop="phone_code">
            <el-input
              v-model="form.phone_code"
              clearable
              placeholder="请输入验证码"
            >
              <template slot="suffix">
                <span class="span1">|</span>
                <el-button :disabled="phoneCodeDisabled" type="text" @click="get_phone_code">{{ phoneCodeText }}</el-button>
              </template>
            </el-input>
          </el-form-item>
        </div>
      </template>
    </el-form>
    <button
      type="button"
      class="next-btn next-large next-btn-primary sc-bWXABl hyIOvU"
      style="width: 100%;margin-top:20px;"
      @click="submit('form')"
    >
      <span class="next-btn-helper">注册</span>
    </button>
    <div style="width: 56px;height: 1px;background: rgba(0,0,0,0.1000);margin:10px auto;" />
    <a
      class="login-blocks"
      type="primary"
      @click="to_login"
    >
      已有账号？去登录
    </a>
  </div>
</template>

<script>
import { register_main_user, send_email_verify_code, getPhoneCode } from '@/api/login'
import config from '@/config'

export default {
  data () {
    const checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入您的邮箱'))
      }
      setTimeout(() => {
        if (this.GLOBALREG.email.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱'))
        }
      }, 300)
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入您的手机号'))
      }
      setTimeout(() => {
        if (this.GLOBALREG.phoneNumber.test(value)) {
          callback()
        } else {
          callback(new Error('请输入11位手机号码'))
        }
      }, 300)
    }
    return {
      form: {
        phone: '',
        email: '',
        email_code: '',
        password: '',
        re_password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入登录名', trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }],
        email_code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
        re_password: [{ validator: validatePass, trigger: 'blur' }],
        phone: [{ validator: checkUsername, trigger: 'blur' }],
        phone_code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      input: '',
      type: 'email',
      emailCodeText: '获取验证码',
      emailCodeDisabled: false,
      phoneCodeText: '获取验证码',
      phoneCodeDisabled: false,
      tabList: [
        { name: '邮箱注册', fal: false, type: 'email', show: config.EMAIL_OPEN },
        { name: '账号注册', fal: false, type: 'pwd', show: config.REGISTER_USERNAME_OPEN },
        { name: '手机号注册', fal: false, type: 'phone', show: config.SMS_OPEN }
      ]
    }
  },
  created () {
    if (sessionStorage.phone_code) {
      // this.type = 'phone'
      this.phoneCodeDisabled = true
      this.onPhoneCodeTime()
      this.form.phone = ''
    }
    if (sessionStorage.email_code) {
      // this.type = 'phone'
      this.emailCodeDisabled = true
      this.onEmailCodeTime()
      this.form.email = sessionStorage.getItem('email') || ''
    }
    const showList = this.tabList.filter(({ show }) => show)
    showList[0].fal = true
  },
  methods: {
    get_phone_code () {
      // this.$refs.form.validateField('phone')
      getPhoneCode({
        'phone': this.form.phone
      }).then(res => {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        sessionStorage.phone_code = 60
        this.phoneCodeDisabled = true
        this.onPhoneCodeTime()
        sessionStorage.setItem('phone', this.form.phone)
      })
    },
    onPhoneCodeTime () {
      const vm = this
      let t = Number(sessionStorage.phone_code)
      vm.timer = setInterval(() => {
        t--
        if (t < 1) {
          clearInterval(vm.timer)
          vm.timer = null
          this.phoneCodeDisabled = false
          this.phoneCodeText = '获取验证码'
          sessionStorage.removeItem('phone_code')
          sessionStorage.removeItem('phone')
        } else {
          sessionStorage.phone_code = t
          this.phoneCodeText = `${t}s`
        }
      }, 1000)
    },
    onEmailCodeTime () {
      const vm = this
      let t = Number(sessionStorage.email_code)
      vm.timer = setInterval(() => {
        t--
        if (t < 1) {
          clearInterval(vm.timer)
          vm.timer = null
          this.emailCodeDisabled = false
          this.emailCodeText = '获取验证码'
          sessionStorage.removeItem('email_code')
          sessionStorage.removeItem('email')
          // this.onTab({ type: 'phone' }, 1)
        } else {
          sessionStorage.email_code = t
          this.emailCodeText = `${t}s`
        }
      }, 1000)
    },
    get_email_code () {
      this.$refs.form.validateField('email')
      send_email_verify_code({
        'email': this.form.email
      }).then(res => {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        sessionStorage.email_code = 60
        this.emailCodeDisabled = true
        this.onEmailCodeTime()
        sessionStorage.setItem('email', this.form.email)
      })
    },
    to_login () {
      this.$emit('to_login')
    },
    submit () {
      this.$refs.form.validate(async (valid, rule) => {
        if (valid) {
          const code = this.tabList.findIndex(item => item.fal) ? this.form.phone_code : this.form.email_code
          const params = {
            'name': this.form.username, // 用户名
            'verify_code': code,
            'phone': this.form.phone,
            'email': this.form.email,
            password: this.form.password,
            re_password: this.form.re_password,
            'register_type': (this.tabList.findIndex(item => item.fal) + 1)
          }
          register_main_user(params).then(res => {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
            this.to_login()
          })
        } else {
          return false
        }
      })
    },
    onTab (item, i) {
      this.tabList.forEach((da) => (da.fal = false))
      this.tabList[i].fal = true
      this.type = this.tabList[i].type
      this.$refs.form.resetFields()
      this.form.phone = sessionStorage.getItem('phone') || ''
      this.form.email = sessionStorage.getItem('email') || ''
    }
  }
}
</script>

<style lang='less' scoped src="./index.less">
</style>

<style scoped>
.login-blocks {
  text-decoration: none;
  margin-top: 5px;
  display:inline-block;
  text-align: center;
  width: 100%;
}
.login_page >>> .phone_code .el-input__suffix {
  line-height: 38px;
  cursor: pointer;
  padding-right: 5px;
}
</style>

