import { getSearchObject } from '@/utils'
import { mapActions } from 'vuex'
import { signInApi, login_phoneApi, getPhoneCode, check_sub_name } from '@/api/login'

export default {
  name: 'login',

  mounted () {
    if (localStorage.getItem('systemImage')) {
      const res = { data: JSON.parse(localStorage.getItem('systemImage')) }
      this.$refs.login_page.style.backgroundImage = 'url(' + this.$config.img_base_url + res.data.LoginBackground + ')'
    } else {
      this.getSystemInfoImage().then(res => {
        this.$refs.login_page.style.backgroundImage = 'url(' + this.$config.img_base_url + res.data.LoginBackground + ')'
      })
    }
    const son_username = this.$route.params.son_username

    if (son_username) {
      this.login_type = 0
      this.form.son_username = '@' + son_username

      this.$nextTick(() => {
        this.setCursorPosition(document.getElementById('subInput'), 0)
      })
    }
  },

  methods: {
    ...mapActions([
      'getUserInfo',
      'getMenuList',
      'getSystemInfoImage'
    ]),

    setCursorPosition (elem, index) {
      const val = elem.value
      const len = val.length

      // 超过文本长度直接返回
      if (len < index) return
      setTimeout(function () {
        elem.focus()
        if (elem.setSelectionRange) { // 标准浏览器
          elem.setSelectionRange(index, index)
        } else { // IE9-
          const range = elem.createTextRange()
          range.moveStart('character', -len)
          range.moveEnd('character', -len)
          range.moveStart('character', index)
          range.moveEnd('character', 0)
          range.select()
        }
      }, 10)
    },

    // 获取验证码
    get_phone_code () {
      this.$refs.form.validateField('phone')
      if (this.form.phone) {
        getPhoneCode({
          phone: this.form.phone,
          client_uuid: this.$route.query.client_uuid,
          redirect_url: this.$route.query.redirect_url,
          need_redirect: this.$route.query.need_redirect
        }).then(res => {
          sessionStorage.phone_code = 60
          this.phoneCodeDisabled = true
          this.onCodeTime()
          sessionStorage.setItem('phone', this.form.phone)
        })
      }
    },

    // 子账号登录
    next () {
      const domain = this.$config.DEFAULT_DOMAIN

      const username = (this.form.son_username.indexOf('@') > -1) ? this.form.son_username : (this.form.son_username + '@' + domain)

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          check_sub_name(username).then(res => {
            this.loading = false
            if (!res.code) {
              this.login()
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },

    objectToQuery () {
      const obj = arguments[0]
      const prefix = arguments[1]
      if (typeof obj !== 'object') return ''
      const attrs = Object.keys(obj)
      return attrs.reduce((query, attr, index) => {
        // 判断是否是第一层第一个循环
        if (index === 0 && !prefix) query += '?'
        if (typeof obj[attr] === 'object') {
          const subPrefix = prefix ? `${prefix}[${attr}]` : attr
          query += this.objectToQuery(obj[attr], subPrefix)
        } else {
          if (prefix) {
            query += `${prefix}[${attr}]=${obj[attr]}`
          } else {
            query += `${attr}=${obj[attr]}`
          }
        }
        // 判断是否是第一层最后一个循环
        if (index !== attrs.length - 1) query += '&'
        return query
      }, '')
    },

    // 账号登录
    login () {
      this.$refs.form.validate(async (valid, rule) => {
        // 登录类型
        const account_type = this.login_type ? 'user_main' : 'user_sub'
        const login_type = account_type == 'user_sub' ? 'username' : this.GLOBALREG.email.test(this.form.username) ? 'email' : 'username'

        // 系统默认domain
        const domain = this.$config.DEFAULT_DOMAIN
        const son_username = (this.form.son_username.indexOf('@') > -1) ? this.form.son_username : (this.form.son_username + '@' + domain)

        // 账号密码
        const username = this.login_type ? this.form.username : son_username
        const password = this.login_type ? this.form.password : this.form.son_password

        // 路由参数
        const route = {
          client_uuid: this.$route.query.client_uuid || '',
          redirect_url: this.$route.query.redirect_url || '',
          need_redirect: this.$route.query.need_redirect || ''
        }

        if (valid) {
          try {
            this.loading = true
            const res = await signInApi({ login_type, account_type, username, password, ...route })

            this.loading = false
            // 重定向
            this.redirect(res || {})

            // 获取个人信息
            this.getUserInfo(res.data.user_info)

            // 获取菜单数据
            this.getMenuList()

            // 进入页面
            res.code === 0 && this.sign()
          } catch (error) {
            this.loading = false
          }
        } else {
          return false
        }
      })
    },
    login_phone () {
      this.$refs.form.validate(async (valid, rule) => {
        if (valid) {
          try {
            const { need_redirect, redirect_url, client_uuid } = this.$route.query

            // 路由参数
            const route = {
              need_redirect,
              redirect_url,
              client_uuid: need_redirect ? client_uuid : this.$config.client_uuid
            }

            this.loading = true
            const res = await login_phoneApi({
              account_type: 'user_main',
              login_type: 'phone',
              username: this.form.phone,
              sms_code: this.form.phone_code,
              ...route
            })

            // 重定向
            this.redirect(res || {})

            // 获取个人信息
            this.getUserInfo(res.data.user_info)
            // 获取菜单数据
            this.getMenuList()

            // 进入页面
            res.code === 0 && this.sign()
          } catch (error) {
            this.loading = false
          }
        } else {
          return false
        }
      })
    },
    // 登录 托管部&&H5 跳转到H5，否则跳转PC
    sign () {
      const path = this.$route.query.redirect || this.defaultUrl

      this.$router.push({
        path,
        query: getSearchObject()
      })

      // 储存登陆时间
      const requestTime = new Date().getTime()
      localStorage.setItem('requestTime', requestTime)
      // 每次重新登录之后清除一下 fullPath
      sessionStorage.removeItem('fullPath')
    },
    // 重定向
    redirect (res) {
      // 重定向地址
      if (res.redirect_url) {
        const token_obj = {}
        for (const key in res.data) {
          if (key.includes('token')) {
            token_obj[key] = res.data[key]
          }
        }
        const path = res.redirect_url

        const queryString = Object.keys(token_obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(token_obj[key])}`).join('&')
        const separator = decodeURIComponent(path).includes('?') ? '&' : '?'
        const redirectUrl = path + separator + queryString

        window.location.href = redirectUrl
      }
    }
  }
}
