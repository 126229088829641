<template>
  <div
    ref="login_page"
    class="login_page"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <div class="login_wrap">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        @submit.native.prevent
      >

        <img
          v-if="access_url"
          style="margin: auto;display: block;"
          :src="access_url"
          class="logo"
        >

        <h1 v-if="login_type === 0" class="title">登录</h1>
        <!-- 子账号登录 -->
        <template v-if="login_type === 0">
          <template v-if="!nextType">
            <!-- 子用户账号 -->
            <el-form-item prop="son_username">
              <el-input
                id="subInput"
                v-model="form.son_username"
                type="text"
                clearable
                placeholder="请输入用户名。例：zhangsan@company"
                @keyup.enter.native="next"
              />
            </el-form-item>
            <!-- 子用户密码 -->
            <el-form-item class="is-required" prop="son_password">
              <el-tooltip
                v-model="isTooltip"
                placement="right"
                :disabled="!isTooltip"
                manual
                effect="light"
              >
                <template slot="content">
                  <span style="color: #e6a23c;font-size:13px">
                    <i class="el-icon-warning" />
                    请注意：密码里包含空格!
                  </span>
                </template>

                <el-input
                  v-model="form.son_password"
                  clearable
                  placeholder="请输入密码"
                  show-password
                  type="password"
                  @input="inputPassword"
                  @keyup.enter.native="next"
                />
              </el-tooltip>
              <p class="dCixPR" style="margin:3px; margin-bottom: 0;">如果您忘记密码，请联系主账号或 IAM 管理员在 IAM 控制台重置密码。</p>
            </el-form-item>
          </template>
          <template v-else>
            <p><span class="errorIcon">*</span>用户密码</p>
            <el-form-item class="is-required">
              <el-input
                v-model="form.son_password"
                clearable
                placeholder=""
                show-password
                type="password"
                @keyup.enter.native="login"
              />
              <p class="dCixPR">如果您忘记密码，请联系主账号或 IAM 管理员在 IAM 控制台重置密码。</p>
            </el-form-item>
          </template>
        </template>
        <!-- 主账号登录 -->
        <template v-if="login_type === 1">
          <div class="login_tab">
            <div
              v-for="(item, index) in tabList"
              v-show="item.show"
              :key="index"
              :class="['login_tab_list', item.fal ? 'login_tab_text' : '']"
              @click="onTab(item, index)"
            >
              {{ item.name }}
              <div v-if="item.fal" class="login_tab_active" />
            </div>
          </div>

          <!-- 账号登录 -->
          <template v-if="type === 'pwd'">
            <el-form-item prop="username">
              <el-input
                v-model="form.username"
                style="margin-top: 20px"
                clearable
                placeholder="请输入手机号/邮箱/账号名"
              />
            </el-form-item>
            <el-form-item prop="password" style="margin-bottom: 0px">
              <el-tooltip
                v-model="isTooltip"
                placement="right"
                :disabled="!isTooltip"
                manual
                effect="light"
              >
                <template slot="content">
                  <span style="color: #e6a23c;font-size:13px">
                    <i class="el-icon-warning" />
                    请注意：密码里包含空格!
                  </span>
                </template>
                <el-input
                  v-model="form.password"
                  show-password
                  type="password"
                  clearable
                  placeholder="请输入登录密码"
                  @input="inputPassword"
                  @keyup.enter.native="login"
                />
              </el-tooltip>
            </el-form-item>
            <a
              v-if="config.SMS_OPEN||config.EMAIL_OPEN"
              class="login-blocks"
              style="text-align: right;padding:5px 3px"
              type="primary"
              @click="forget_password"
            >忘记密码</a>

            <a
              v-else
              class="login-blocks"
              style="text-align: right;visibility:hidden;padding:5px 3px"
              type="primary"
            >忘记密码</a>

          </template>

          <!-- 手机号登录 -->
          <template v-if="type === 'phone'">
            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                style="margin-top: 20px"
                clearable
                placeholder="请输入手机号"
              />
            </el-form-item>

            <div class="phone_code">
              <el-form-item prop="phone_code" style="margin-bottom: 0px">
                <el-input
                  v-model="form.phone_code"
                  class="system-code_left"
                  type="code"
                  placeholder="请输入验证码"
                >
                  <template slot="suffix">
                    <span class="span1">|</span>
                    <el-button :disabled="phoneCodeDisabled" type="text" @click="get_phone_code">{{ phoneCodeText }}</el-button>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <a
              class="login-blocks"
              style="text-align: right;visibility:hidden;padding:5px 3px"
              type="primary"
            >手机号无法验证</a>
          </template>
        </template>

        <!-- 注册 -->
        <register v-if="login_type === 3" @to_login="to_login" />

        <!-- 重置密码 -->
        <resetPassword v-if="login_type === 4" @to_login="to_login" />

        <div class="btns">
          <template v-if="login_type === 0">
            <el-button
              type="button"
              class="next-btn next-large next-btn-primary sc-bWXABl hyIOvU"
              style="width: 100%;background: #ea7823;"
              :loading="loading"
              @click="next"
            >
              <span class="next-btn-helper">登录</span>
            </el-button>
          </template>
          <template v-if="login_type === 1">
            <!-- 账号登录 -->
            <el-button
              v-if="type === 'pwd'"
              type="button"
              class="next-btn next-large next-btn-primary sc-bWXABl hyIOvU"
              style="width: 100%;background: #ea7823;"
              :loading="loading"
              @click="login"
            >
              <span class="next-btn-helper">登录</span>
            </el-button>
            <!-- 手机号登录 -->
            <el-button
              v-if="type === 'phone'"
              type="button"
              class="next-btn next-large next-btn-primary sc-bWXABl hyIOvU"
              style="width: 100%;background: #ea7823;"
              :loading="loading"
              @click="login_phone"
            >
              <span class="next-btn-helper">登录</span>
            </el-button>
          </template>
          <template v-if="login_type != 3 && login_type != 4 && config.IF_SHOW_MAIN">
            <button
              type="button"
              class="next-btn next-large sc-bWXABl hyIOvU"
              style="
                  width: 100%;
                  color: #000;
                  background: #fff;
                  margin-top: 10px;
                  border: 1px solid rgba(0, 0, 0, 0.15);
            "
              @click="mainLogin"
            >
              <span class="next-btn-helper">{{ toogText }}</span>
            </button>
          </template>

          <template v-if="login_type === 1&&(config.EMAIL_OPEN||config.REGISTER_USERNAME_OPEN||config.SMS_OPEN)">
            <div style="width: 56px;height: 1px;background: rgba(0, 0, 0, 0.1);margin: 10px auto;" />
            <a class="login-blocks" type="primary" @click="register">我是新的主用户，去注册</a>
          </template>
        </div>

        <div class="tips-apply" v-html="config.LOGIN_CUSTOM_PROMPT" />
      </el-form>
    </div>
  </div>
</template>

<script>
import register from './register'
import resetPassword from './reset'
import login from './login.js'
import config from '@/config'
import { get_logo } from '@/api/login'

/**
 *
 * login_type
 * 0子账号登录 1主账号 3注册 4重置密码
 *
 */

export default {
  components: {
    register,
    resetPassword
  },
  mixins: [login],
  data () {
    const checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入您的手机号'))
      }
      setTimeout(() => {
        if (this.GLOBALREG.phoneNumber.test(value)) {
          callback()
        } else {
          callback(new Error('请输入11位手机号码'))
        }
      }, 300)
    }
    return {
      config,
      isTooltip: false,
      access_url: '',
      type: 'pwd',
      nextType: 0,
      defaultUrl: '/view',
      loading: false,
      login_type: 1, // 默认主账号登录
      phoneCodeDisabled: false,
      phoneCodeText: '获取验证码',
      form: {
        phone: '',
        username: '',
        password: '',
        son_username: '',
        son_password: '',
        phone_code: ''
      },
      tabList: [
        { name: '账号登录', fal: true, type: 'pwd', show: true },
        { name: '手机号登录', fal: false, type: 'phone', show: config.SMS_OPEN }
      ],
      rules: {
        username: [{ required: true, message: '请输入手机号/邮箱/账号名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        son_username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        son_password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        phone: [{ validator: checkUsername, trigger: 'blur' }],
        phone_code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    toogText () {
      const t = this.login_type ? '子' : '主'
      return `使用${t}账号登录`
    }
  },
  created () {
    /**
     *  默认判断展示的登录页面
     *  USER_MAIN 主用户
     *  USER_SUB 子用户
     */
    const { DEFAULT_LOGIN_USER_TYPE, IF_SHOW_MAIN } = this.config

    if (DEFAULT_LOGIN_USER_TYPE === 'USER_MAIN') {
      this.login_type = 1
    } else if (DEFAULT_LOGIN_USER_TYPE === 'USER_SUB' || !IF_SHOW_MAIN) {
      this.login_type = 0
    }

    if (sessionStorage.phone_code) {
      this.tabList.forEach((da) => (da.fal = false))
      this.tabList[1].fal = true
      this.type = 'phone'
      this.phoneCodeDisabled = true
      this.onCodeTime()
      this.form.phone = sessionStorage.getItem('phone') || ''
    } else {
      this.type = 'pwd'
    }

    this.get_logo_image()
  },
  methods: {
    inputPassword (val) {
      const judgeFn = new RegExp(/\s+/g)
      if (judgeFn.test(val)) {
        this.isTooltip = true
      } else {
        this.isTooltip = false
      }
    },
    async  get_logo_image () {
      const { data } = await get_logo()
      this.access_url = data || ''
    },
    onCodeTime () {
      const vm = this
      let t = Number(sessionStorage.phone_code)
      vm.timer = setInterval(() => {
        t--
        if (t < 1) {
          clearInterval(vm.timer)
          vm.timer = null
          this.phoneCodeDisabled = false
          this.phoneCodeText = '获取验证码'
          sessionStorage.removeItem('phone_code')
          sessionStorage.removeItem('phone')
        } else {
          sessionStorage.phone_code = t
          this.phoneCodeText = `${t}s`
        }
      }, 1000)
    },
    onTab (item, i) {
      this.tabList.forEach((da) => (da.fal = false))
      this.tabList[i].fal = true
      this.type = this.tabList[i].type
      this.$refs.form.resetFields()

      if (item.type == 'pwd') {
        this.form.username = localStorage.getItem('loginName') ? localStorage.getItem('loginName') : ''
        this.form.password = ''
        this.form.code = ''
      } else {
        this.form.phone = ''
        this.form.phone_code = ''
      }
    },
    mainLogin () {
      const typeID = this.login_type ? 0 : 1
      this.login_type = typeID
      this.$refs['form'].resetFields()
      this.isTooltip = false
    },
    // 去注册
    register () {
      this.login_type = 3
    },
    // 忘记密码
    forget_password () {
      this.login_type = 4
    },
    // 去登录
    to_login () {
      this.login_type = 1
    },
    icon_left () {
      this.nextType = 0
    }
  }
}
</script>

<style lang='less' scoped src="./index.less">
</style>

<style scoped>
.login_page >>> .phone_code .el-input__suffix {
  line-height: 38px;
  cursor: pointer;
  padding-right: 5px;
}
.icon_left{
  cursor: pointer;
}
</style>
